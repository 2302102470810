import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { gatedSectionsRestaurantDive } from "../../../data/landing/gated-v2";
import { viewports } from "../../../style-vars";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";

const LargeFeaturesV15 = loadable(() =>
  import("../../../components/CustomGated/LargeFeaturesV1-5")
);
const WhyHeroBg = loadable(() =>
  import("../../../components/_NewAnimated/NewWhyHero/WhyHeroBg")
);
const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);

const formDataToSend = ["firstname", "email", "phone", "company", "get_demo"];
const shortFormDataToSend = [
  "firstname",
  "email",
  "phone",
  "company",
  "get_demo",
];
const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetRestaurantDive = () => {
  const formHandler = (props) => {
    console.log(props);
  };

  return (
    <LpLayout phoneNumber="+18775594225" phoneText="(877) 559-4225">
      <SEO
        title="How Modern Tech Can Alleviate Labor Challenges | SpotOn"
        description=""
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row gutter={[32, 32]}>
        <Col lg={12} className="gated-restaurant-dive-text">
          <div className="gate-heading">
            <Heading injectionType={2} level={1} symbol="." withBlueSymbol>
              Alleviate your restaurant’s labor challenges and improve
              operational efficiency{" "}
            </Heading>
            <p className="gated-intro-para ">
              The restaurant industry was one of the most heavily impacted areas
              during the COVID-19 lockdowns. Now that the country has largely
              reopened, a different and unexpected challenge is rocking the
              restaurant industry: the labor crisis.
            </p>
          </div>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <LargeFeaturesV15 sectionData={gatedSectionsRestaurantDive} />
        </Col>
        <Col lg={12} className="gated-restaurant-dive-form">
          <WhyHeroBg
            imageName="background.png"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="gated-form-cont">
              <GatedForm
                pageTitle="Title of the downloadable content"
                pageSubtext={subtext}
                formTitle="Download your free whitepaper."
                formItems={data}
                formId="c3221775-397e-41fe-ac5f-f822c36d96df"
                formDataToSend={formDataToSend}
                shortFormDataToSend={shortFormDataToSend}
                featureTitle="What you get"
                featureSubtext="Fill out the form to get your free download today."
                thankYouPath="/gated-assets/modern-tech-labor-optimizations/thank-you"
                gatedCampaign="Modern Tech Labor Optimizations"
                blockList={blockList}
                onFormSubmit={formHandler}
                lineOfBusiness={lineOfBusiness.VENUES}
              />
            </div>
          </WhyHeroBg>
        </Col>
      </Row>

      <style jsx global>{`
        @media ${viewports.mdOrSmaller} {
          .gated-restaurant-dive-text {
            order: 2;
            margin-top: 60px;
          }

          .gated-restaurant-dive-form {
            order: 1;
            margin-top: -63px;
          }
        }
      `}</style>
    </LpLayout>
  );
};

export default GatedAssetRestaurantDive;
